<template>
  <div class="mo-reserve-wrap">
    <div class="mo-reserve-wrap--wait">
      <div class="mo-reserve-wrap--wait--top">
        <div class="mo-reserve-wrap--wait--top--phone">{{ PHONE_NUM }}</div>
        <button type="button" class="btn-refresh">새로고침</button>
      </div>
      <div class="mo-reserve-wrap--wait--container">
        <div class="mo-reserve-wrap--wait--container-list">
          <div class="mo-reserve-wrap--wait--container-list--title type-no">대기번호</div>
          <div class="mo-reserve-wrap--wait--container-list--info">{{ WAIT_NO }}<span
              class="mo-reserve-wrap--wait--container-list--info-unit">번</span>
          </div>
        </div>
        <div class="mo-reserve-wrap--wait--container-list">
          <div class="mo-reserve-wrap--wait--container-list--title type-wait">내 앞 상담대기</div>
          <div class="mo-reserve-wrap--wait--container-list--info">{{ WAIT_BEFORE_CNT }}<span
              class="mo-reserve-wrap--wait--container-list--info-unit">명</span>
          </div>
        </div>
        <div class="mo-reserve-wrap--wait--container-list">
          <div class="mo-reserve-wrap--wait--container-list--title type-time">예상 시간</div>
          <div class="mo-reserve-wrap--wait--container-list--info">{{ WAIT_TIME }}<span
              class="mo-reserve-wrap--wait--container-list--info-unit">분</span>
          </div>
        </div>
      </div>
    </div>
    <div class="mo-reserve-wrap--btn">
      <button type="button">확인</button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'CAP0620',
  components: {},
  props: {},
  data() {
    return {
      PHONE_NUM: '010-****-7449', //전화번호
      WAIT_NO: '6',               //대기번호
      WAIT_BEFORE_CNT: '3',       //내 앞 상담대기
      WAIT_TIME: '20',            //예상 시간
    }
  },
  mounted() { },
  computed: {},
  methods: {

  }
}
</script>

<style>
</style>